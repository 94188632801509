<template>
    <div id="filter">
        <button class="p-close" @click="ClosefilterNav()">Close</button>
        <h3 class="f-st">Browse Collection</h3>
        <ul>
            <li v-for="(items,index) in collections" :key="index"> 
                <router-link @click="active = !active" v-bind:class="{'router-link-active' : active}" :to="{path: '/collection/'+ items.slug}">  
                    <p  class="ml10">{{items.name}}</p>
                </router-link> 
            </li>
        </ul>
        <ul>
            <li>
                <a class="">--------------------</a>
            </li>
        </ul>
        <ul v-if="subCollection && subCollection.hits && subCollection.hits.length == 0">
        </ul>
        <ul v-if="subCollection && !subCollection.hits">
            <li v-for="(item,index) in subCollection" :key="index">
                <router-link @click="subActive = !subActive" v-bind:class="{'router-link-active' : subActive}" :to="{path: '/collection/'+ slug + '/' + item.slug  }">
                    <p class="ml10">{{item.name}}</p>
                </router-link>
            </li>
        </ul>
    </div>

</template>

<script>
export default {
    data(){
        return{
            active: false,
            subActive: false,
            collections:[],
            subCollection:[],
            error: false,
            slug: '',
            name: '',
            icon: '',
            parentname: ''
        }
    },
    watch: {
        "$route.params": {
        handler(newValue, preValue) {
            // this.init()
            this.ClosefilterNav()
        },
        immediate: true
        }
    },
    mounted(){
        if(document.getElementById("filter")){
            document.getElementById("filter").style.transform = 'translate3d(0px, 300px, 0px)'
            var element = document.getElementById("filter")
            var x = window.matchMedia("(max-width: 579px)")
            myFunction(x)
            function myFunction(x){
                if (x.matches) { 
                        element.style.visibility = 'hidden'
                        element.style.transition= '0.5s ease-in-out';
                }else{
                    element.style.visibility = 'visible'
                    element.style.transition= '0.5s ease-in-out';
                }
            }
        }
        window.addEventListener('scroll', this.handleScroll);
    },
    created(){
        this.init()
    },
    methods:{
        init(){
            if(this.$route.params.slug){
                this.slug = this.$route.params.slug;
            }
            this.$store.dispatch("CollectionRead", {
                bool: {
                    should: [
                        {
                            match_phrase: {
                                event: this.$store.state.event
                            }
                        },
                        {
                            match_phrase: {
                                'has_parent': 'No'
                            }
                        },
                        {
                            match_phrase: {
                                'status': 'Active'
                            }
                        },
                    ],
                    minimum_should_match: 3
                }
            })
            .then(response => {
                this.collections = []
                this.collections = response
                //this.collections.sort((a, b) => (a.sort_no > b.sort_no) ? 1 : ((b.sort_no > a.sort_no) ? -1 : 0));
            })
            if(this.slug){
                this.$store.dispatch("CollectionRead", {
                            bool: {
                                should: [
                                {
                                    match_phrase: {
                                        event: this.$store.state.event
                                    }
                                },
                                {
                                    match_phrase: {
                                        parent_name: this.slug
                                    }
                                },
                                {
                                    match_phrase: {
                                        'status': 'Active'
                                    }
                                },
                                ],
                                minimum_should_match: 3
                            }
                        
                })
                .then(response => {
                    this.subCollection = []
                    this.subCollection = response
                    //this.subCollection.sort((a, b) => (a.sort_no > b.sort_no) ? 1 : ((b.sort_no > a.sort_no) ? -1 : 0));
                })
            }
        },
        handleScroll(){
            if(document.getElementById("filter")){
                var filters = document.getElementById("filter");
                    if (window.scrollY > 50  ) {
                        filters.style.transform = 'translate3d(0px, 80px, 0px)'
                    } else {
                        filters.style.transform = 'translate3d(0px, 300px, 0px)'
                    }        
            }
        },
        ClosefilterNav(){
            if(document.getElementById("filter")){
                document.getElementById('filter').classList.remove('display')
            }
        }
    }
}
</script>

<style scoped>
.router-link-active{
    color: #ae97a2;
    font-style: italic
}
.router-link-active:before {
    content: '';
    height: 1px;
    width: 2vw;
    margin-right: 0.2vw;
    display: inline-block;
    background: #ae97a2
}
.sticky {
    top: -2%;
    margin-top: 1% !important;
}
@media only screen and (min-width: 580px){
    #filter{
        background: #f3f2f5;
        padding: 10px;
        pointer-events: all !important;
        border-radius: 10px;
        z-index: 6;
    }
}

</style>